"use strict";
var Handlebars = require('handlebars');
$.fn.feedback = function (options) {
    var that = this;
    var template = '' +
        '<div class="basicFeedback">' +
        '    <div class="row">' +
        '        <div class="col-md-6">' +
        '            <p class="questionText">{{question}}</p>' +
        '        </div>' +
        '        <div class="col-sm-6 col-md-3">' +
        '            <a href="#" class="btn btn-primary btn-block btn-small container--feedback__button yes btn-space"><span class="icon-text icon-text--check icon-text--p0"></span> {{yesbutton}}</a>' +
        '        </div>' +
        '        <div class="col-sm-6 col-md-3">' +
        '            <a href="#" class="btn btn-default btn-block btn-small container--feedback__button no"><span class="icon-text icon-text--remove icon-text--p0"></span> {{nobutton}}</a>' +
        '        </div>' +
        '    </div>' +
        '</div>' +
        '<!-- Vises kun hvis kunden velger "nei" -->' +
        '<div class="detailedFeedback" style="display: none;">' +
        '        <div class="row">' +
        '            <div class="col-md-6">' +
        '                <p class="questionText">{{whynot}}</p>' +
        '            </div>' +
        '            <div class="formfield col-md-6">' +
        '               <textarea id="FeedbackMessage" class="formfield__input" style="width: 100%;"></textarea>' +
        '               <div class="row">' +
        '            <div class="col-md-6">' +
        '                <a class="btn btn-primary btn-block btn-small container--feedback__button submit">{{sendbutton}}</a>' +
        '            </div>' +
        '            <div class="col-md-6">' +
        '                <a class="btn btn-default btn-block btn-small container--feedback__button cancel">{{cancelbutton}}</a>' +
        '            </div>' +
        '            </div>' +
        '            </div>' +
        '        </div>' +
        '</div>' +
        '<!-- Vises kun hvis kunden velger "Ja eller submit" -->' +
        '<div class="thankyouFeedback" style="display: none;">' +
        '    <p>{{thankyou}}</p>' +
        '</div>';
    options.url = options.url || window.location.protocol + "//" + window.location.host + "/" + window.location.pathname;
    options.contentid = that.data("contentid");
    options.sendbutton = that.data("sendbutton");
    options.cancelbutton = that.data("cancelbutton");
    options.whynot = that.data("whynot");
    options.thankyou = that.data("thankyou");
    options.yesbutton = that.data("yesbutton");
    options.nobutton = that.data("nobutton");
    options.question = that.data("question");
    var ratingBackEndUrl = options.backendurl || "/api/Rating/AddRating";
    var visible = get_cookie("Feedback-" + options.url);
    if (visible === "") {
        var UserId = get_cookie("_ga");
        var contentId = options.contentid || "0";
        var templateCompiled = Handlebars.compile(template);
        that.html(templateCompiled(options));
    }
    that.find('.basicFeedback .yes').click(function (e) {
        e.preventDefault();
        set_cookie("Feedback-" + options.url, "yes");
        var postData = { url: options.url, nicecontent: true, comment: "", userid: UserId, contentRefId: contentId };
        $.ajax({ url: ratingBackEndUrl, data: postData, dataType: "json", traditional: true });
        that.find(".basicFeedback").slideUp();
        that.find(".thankyouFeedback").slideDown();
    });
    that.find('.basicFeedback .no').click(function (e) {
        e.preventDefault();
        that.find(".basicFeedback").slideUp();
        that.find(".detailedFeedback").slideDown();
    });
    that.find('.detailedFeedback .submit').click(function (e) {
        e.preventDefault();
        set_cookie("Feedback-" + options.url, "no");
        var msg = $('textarea#FeedbackMessage').val();
        var postData = { url: options.url, nicecontent: "false", comment: msg, userid: UserId, contentRefId: contentId };
        $.ajax({ url: ratingBackEndUrl, data: postData, dataType: "json", traditional: true });
        that.find(".detailedFeedback").slideUp();
        that.find(".thankyouFeedback").slideDown();
    });
    that.find('.detailedFeedback .cancel').click(function (e) {
        e.preventDefault();
        that.find(".detailedFeedback").slideUp();
        that.find(".basicFeedback").slideDown();
    });
    /* code to handle cookies */
    function get_cookie(c_name) {
        if (document.cookie.length > 0) {
            var c_start = document.cookie.indexOf(c_name + "=");
            if (c_start !== -1) {
                c_start = c_start + c_name.length + 1;
                var c_end = document.cookie.indexOf(";", c_start);
                if (c_end === -1) {
                    c_end = document.cookie.length;
                }
                return unescape(document.cookie.substring(c_start, c_end));
            }
        }
        return "";
    }
    function set_cookie(c_name, value) {
        var expiredays = 999;
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + expiredays);
        document.cookie = c_name + "=" + escape(value) + (expiredays === null ? "" : ";expires=" + exdate.toUTCString());
    }
};
$(function () {
    if ($("#contentRating").length > 0) {
        $("#contentRating")
            .feedback({
            backendurl: "/api/Rating/AddRating"
        });
        if ($(".basicFeedback").length === 0) {
            $("#feedbackContainer").hide();
        }
    }
});
